<template>
    <div>
        <b-tabs>
            <b-card-actions
            title="Filters"
            action-collapse
            >
                <b-card-text>
                    <b-row>
                    <b-col md="4">
                            <b-form-group
                                label="Branch"
                                label-for="branch-source-filter"
                            >
                                <custom-autopopulate
                                    id="branch-source-filter"
                                    module="branches"
                                    label="name"
                                    :value="branchId"
                                    @changed="branchesChanged"
                                />
                            </b-form-group>
                        </b-col>
                        <custom-client-select
                            :branch-id="branchId"
                            :value="clientId"
                            @changed="clientsChanged"
                        />
                    </b-row>
                </b-card-text>
            </b-card-actions>
            <List
                model="vehicles"
                :can-delete="true"
                :can-edit="false"
                :can-view="true"
                default-sort="id"
                default-sort-order="desc"
                :where="memberCondition"
            />
        </b-tabs>
    </div>
</template>

<script>
import {
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
} from 'bootstrap-vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import List from '../common/list';
import CustomAutopopulate from '../common/components/CustomAutopopulate';
import CustomClientSelect from '../common/components/CustomClientSelect';
export default {
    components: {
        BTabs,
        BCardText,
        List,
        BCardActions,
        BRow,
        BCol,
        BFormGroup,
        CustomAutopopulate,
        CustomClientSelect,
    },
    data() {
        return {
            memberCondition: {
                where_fields: [],
                where_values: [],
            },
            branchId: 0,
            clientId: 0,
        };
    },
    // async created() {
    //     this.fetchData();
    // },
    methods: {
        clientsChanged(val) {
            this.clientId = val;
            if (val > 0) {
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'clients.id',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('clients.id')
                ] = val;
            } else {
                const ind = this.memberCondition.where_fields.indexOf(
                    'clients.id',
                );
                this.memberCondition.where_fields.splice('ind', 1);
                this.memberCondition.where_values.splice('ind', 1);
            }
        },
        branchesChanged(val) {
            console.log(val);
            if (val > 0) {
                this.branchId = val;
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'branch_id',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('branch_id')
                ] = val;
            } else {
                const ind = this.memberCondition.where_fields.indexOf(
                    'branch_id',
                );
                this.memberCondition.where_fields.splice('ind', 1);
                this.memberCondition.where_values.splice('ind', 1);
            }
        },
        refreshList() {
            console.log('called');
        },
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-control.custom-checkbox {
    top: 40%;
}
.checkbox-container {
    justify-content: space-around;
    padding: 2rem 0;
}
.list-group {
    max-height: 150px;
    overflow-y: scroll;
}
</style>
